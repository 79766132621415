import axios from 'axios'
import store from '@/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000
})

instance.interceptors.request.use(
  config => {
    const whiteUrls = ['auth/login']

    if (whiteUrls.includes(config.url)) {
      return config
    }

    const token = localStorage.getItem('token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(undefined, async error => {
  if (error.response?.status >= 500) {
    store.commit('setSnackbar', {
      value: true,
      message: 'Ошибка на сервере',
      error: true
    })
  } else if (error.response?.status === 401) {
    await store.dispatch('auth/logout', false)
  } else if (error.response?.status === 404) {
    return
  }

  throw error
})

export default instance
