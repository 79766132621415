import { io } from 'socket.io-client'
import store from '@/store/index.js'

let socket

async function initSocket() {
  const token = store.state.auth.token
  const authUser = store.state.auth.authUser

  socket = io(process.env.VUE_APP_STORAGE, {
    transports: ['websocket'],
    auth: {
      token
    },
    query: {
      ...(authUser.uuid && { userUuid: authUser.uuid })
    }
  })

  return socket
}

export { initSocket, socket }
