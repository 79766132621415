<template>
  <VApp :class="['default-layout', `${$route.name}-layout`]">
    <Header v-model="drawer" />

    <MainMenu v-model="drawer" />

    <VMain :class="{ 'v-main-close': !drawer }">
      <VContainer fluid>
        <RouterView />
      </VContainer>
    </VMain>

    <SnackbarUi />

    <!--    <EmployeeFillDataDialog />-->
  </VApp>
</template>

<script>
import { initSocket } from '@/plugins/socket'
import Header from '@/components/Header.vue'
import MainMenu from '@/components/MainMenu.vue'
import SnackbarUi from '@/components/SnackbarUi.vue'

export default {
  components: {
    SnackbarUi,
    Header,
    MainMenu
  },

  data: () => ({
    drawer: true
  }),

  watch: {
    '$vuetify.breakpoint.width': {
      // eslint-disable-next-line
      handler (newWidth) {
        this.drawer = newWidth > 600
      }
    }
  },

  created() {
    if (this.$store.getters['auth/isAuth']) {
      initSocket()
    }
  }
}
</script>

<style lang="scss" scoped>
.default-layout {
  min-height: 100vh;
  height: 100%;

  ::v-deep {
    .v-main {
      padding-left: 256px !important;
      background-color: #f4f6f9;

      &.v-main-close {
        padding-left: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .default-layout {
    ::v-deep {
      .v-main-close {
        padding-left: 0 !important;
      }
    }
  }
}
</style>
